import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    postLoading: false,
    posts: [],
    postDetailLoading: false,
    postDetail: {},
    userPostLoading: false,
    userPosts: [],
    postCommentsLoading: false,
    postComments: [],
    postRepliesLoading: false,
    postReplies: [],
    error: null,
    savedPostLoading: false,
    savedPosts: [],
};

const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        fetchPostsStart: (state) => {
            state.postLoading = true;
        },
        fetchPostsSuccess: (state, action) => {
            state.postLoading = false;
            const pushData = action.payload.data[0].page > 1 &&  state.posts.length > 0 ;
            state.posts = pushData
                ? [...state.posts, ...action.payload.data]
                : action.payload.data;
        },
        fetchPostsFail: (state, action) => {
            state.postLoading = false;
            state.error = action.payload;
        },

        fetchPostDetailStart: (state) => {
            state.postDetailLoading = true;
        },
        fetchPostDetailSuccess: (state, action) => {
            state.postDetailLoading = false;
            state.postDetail = action.payload.data;
        },
        fetchPostDetailFail: (state, action) => {
            state.postDetailLoading = false;
            state.error = action.payload;
        },

        fetchUserPostsStart: (state) => {
            state.userPostLoading = true;
        },
        fetchUserPostsSuccess: (state, action) => {
            state.userPostLoading = false;
            const pushData = action.payload.data[0].page > 1 &&
                state.userPosts.length > 0 &&
                state.userPosts[0]?.userID?._id === action.payload.data[0]?.userID?._id;
            state.userPosts = pushData
                ? [...state.userPosts, ...action.payload.data]
                : action.payload.data;
        },
        fetchUserPostsFail: (state, action) => {
            state.userPostLoading = false;
            state.error = action.payload;
        },

        fetchSavedPostsStart: (state) => {
            state.savedPostLoading = true;
        },
        fetchSavedPostsSuccess: (state, action) => {
            state.savedPostLoading = false;
            const pushData = action.payload.data[0].page > 1 &&
                state.savedPosts.length > 0 &&
                state.savedPosts[0]?.userID?._id === action.payload.data[0]?.userID?._id;
            state.savedPosts = pushData
                ? [...state.savedPosts, ...action.payload.data]
                : action.payload.data;
        },
        fetchSavedPostsFail: (state, action) => {
            state.savedPostLoading = false;
            state.error = action.payload;
        },

        fetchPostCommentsStart: (state) => {
            state.postCommentsLoading = true;
        },
        fetchPostCommentsSuccess: (state, action) => {
            state.postCommentsLoading = false;
            state.postComments = action.payload.page > 1
                ? [...state.postComments, ...action.payload.data]
                : action.payload.data;
        },
        fetchPostCommentsFail: (state, action) => {
            state.postCommentsLoading = false;
            state.error = action.payload;
        },

        fetchPostRepliesStart: (state) => {
            state.postRepliesLoading = true;
        },
        fetchPostRepliesSuccess: (state, action) => {
            state.postRepliesLoading = false;
            state.postReplies = action.payload.page > 1
                ? [...state.postReplies, ...action.payload.data]
                : action.payload.data;
        },
        fetchPostRepliesFail: (state, action) => {
            state.postRepliesLoading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchPostsStart,
    fetchPostsSuccess,
    fetchPostsFail,

    fetchPostDetailStart,
    fetchPostDetailSuccess,
    fetchPostDetailFail,

    fetchUserPostsStart,
    fetchUserPostsSuccess,
    fetchUserPostsFail,


    fetchSavedPostsStart,
    fetchSavedPostsSuccess,
    fetchSavedPostsFail,

    fetchPostCommentsStart,
    fetchPostCommentsSuccess,
    fetchPostCommentsFail,

    fetchPostRepliesStart,
    fetchPostRepliesSuccess,
    fetchPostRepliesFail,

} = postSlice.actions;

export default postSlice.reducer;