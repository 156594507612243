import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tvPostsLoading: false,
    tvPosts: [],
    totaltvPosts: 0,
    tvPostLoading: false,
    tvPost: {},
    error: null,
};

const tvPostSlice = createSlice({
    name: 'tvPost',
    initialState,
    reducers: {
        fetchTvPostDetailStart: (state) => {
            state.tvPostLoading = true;
        },
        fetchTvPostDetailSuccess: (state, action) => {
            state.tvPostLoading = false;
            state.tvPost = action.payload.data;
        },
        fetchTvPostDetailFail: (state, action) => {
            state.tvPostLoading = false;
        },
        fetchTvPostsStart: (state) => {
            state.tvPostsLoading = true;
        },
        fetchTvPostsSuccess: (state, action) => {
            state.tvPostsLoading = false;
            const { list, page, count } = action.payload.data;
            const pushData = page > 1 &&
                state.tvPosts.length > 0;
            state.tvPosts = pushData
                ? [...state.tvPosts, ...list]
                : list;
            state.totaltvPosts = count;
        },
        fetchTvPostsFail: (state, action) => {
            state.tvPostsLoading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchTvPostDetailStart,
    fetchTvPostDetailSuccess,
    fetchTvPostDetailFail,
    fetchTvPostsStart,
    fetchTvPostsSuccess,
    fetchTvPostsFail
} = tvPostSlice.actions;

export default tvPostSlice.reducer;