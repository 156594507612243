import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import profileReducer from './profileSlice';
import postReducer from './postSlice';
import utilsReducer from './utilsSlice';
import projectSliceReducer from './projectSlice';
import reviewReducer from './reviewSlice';
import transactionServiceReducer from './transactionServiceSlice';
import tvPostReducer from './tvPostSlice';

const rootReducer = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    utils: utilsReducer,
    posts: postReducer,
    project: projectSliceReducer,
    review: reviewReducer,
    transactionService: transactionServiceReducer,
    tvPost: tvPostReducer
});

export default rootReducer;