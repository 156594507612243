import { Outlet } from 'react-router-dom';
import Header from 'components/Header';

const MinimalLayout = () => (
    <>
        <Header />
        <Outlet />
        {/* <Footer /> */}
    </>
);

export default MinimalLayout;
