import { takeEvery, put, call } from 'redux-saga/effects';
import { getRequest } from '../../network';
import { GET_USER_TRANSACTION_SERVICE, GET_USER_TRANSACTION_SERVICES } from '../apis';
import { fetchTransactionServiceDetailFail, fetchTransactionServiceDetailStart, fetchTransactionServiceDetailSuccess, fetchTransactionServicesFail, fetchTransactionServicesStart, fetchTransactionServicesSuccess } from 'store/slices/transactionServiceSlice';

function* fetchTransactionServicesSaga(action) {
    try {
        const response = yield call(getRequest, GET_USER_TRANSACTION_SERVICES, action.payload);
        yield put(fetchTransactionServicesSuccess(response));
    } catch (error) {
        yield put(fetchTransactionServicesFail(error.message));
    }
}

function* fetchTransactionServiceSaga(action) {
    try {
        const response = yield call(getRequest, GET_USER_TRANSACTION_SERVICE, action.payload);
        yield put(fetchTransactionServiceDetailSuccess(response));
    } catch (error) {
        yield put(fetchTransactionServiceDetailFail(error.message));
    }
}


// Watcher saga
export function* watchTransactionServiceSagas() {
    yield takeEvery(fetchTransactionServicesStart.type, fetchTransactionServicesSaga);
    yield takeEvery(fetchTransactionServiceDetailStart.type, fetchTransactionServiceSaga);
}