import { takeEvery, put, call } from 'redux-saga/effects';
import { getRequest } from '../../network';
import { GET_TV_POSTS, GET_TV_POST_DETAIL } from '../apis';
import { fetchTvPostDetailFail, fetchTvPostDetailStart, fetchTvPostDetailSuccess, fetchTvPostsFail, fetchTvPostsStart, fetchTvPostsSuccess } from 'store/slices/tvPostSlice';

function* fetchTvPostsSaga(action) {
    try {
        console.log('action', action);
        const response = yield call(getRequest, GET_TV_POSTS, action.payload);
        console.log('response', response);
        yield put(fetchTvPostsSuccess(response));
    } catch (error) {
        console.log('error', error);
        yield put(fetchTvPostsFail(error.message));
    }
}

function* fetchTvPostSaga(action) {
    try {
        const response = yield call(getRequest, GET_TV_POST_DETAIL, action.payload);
        yield put(fetchTvPostDetailSuccess(response));
    } catch (error) {
        yield put(fetchTvPostDetailFail(error.message));
    }
}


// Watcher saga
export function* watchTvPostSagas() {
    yield takeEvery(fetchTvPostsStart.type, fetchTvPostsSaga);
    yield takeEvery(fetchTvPostDetailStart.type, fetchTvPostSaga);
}