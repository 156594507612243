import { takeEvery, put, call } from 'redux-saga/effects';
import { getRequest } from '../../network';
import { GET_REVIEWS, GET_REVIEW } from '../apis';
import { fetchReviewDetailFail, fetchReviewDetailStart, fetchReviewDetailSuccess, fetchReviewsFail, fetchReviewsStart, fetchReviewsSuccess } from 'store/slices/reviewSlice';

function* fetchReviewsSaga(action) {
    try {
        const response = yield call(getRequest, GET_REVIEWS, action.payload);
        yield put(fetchReviewsSuccess(response));
    } catch (error) {
        yield put(fetchReviewsFail(error.message));
    }
}

function* fetchReviewSaga(action) {
    try {
        const response = yield call(getRequest, GET_REVIEW, action.payload);
        yield put(fetchReviewDetailSuccess(response));
    } catch (error) {
        yield put(fetchReviewDetailFail(error.message));
    }
}


// Watcher saga
export function* watchReviewSagas() {
    yield takeEvery(fetchReviewsStart.type, fetchReviewsSaga);
    yield takeEvery(fetchReviewDetailStart.type, fetchReviewSaga);
}