import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    projectsLoading: false,
    projects: [],
    totalProjects: 0,
    projectLoading: false,
    project: {},
    error: null,
};

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        fetchProjectDetailStart: (state) => {
            state.projectLoading = true;
        },
        fetchProjectDetailSuccess: (state, action) => {
            state.projectLoading = false;
            state.project = action.payload.data;
        },
        fetchProjectDetailFail: (state, action) => {
            state.projectLoading = false;
        },
        fetchProjectsStart: (state) => {
            state.projectsLoading = true;
        },
        fetchProjectsSuccess: (state, action) => {
            state.projectsLoading = false;
            const { list, page, count } = action.payload.data;
            const pushData = page > 1 &&
                state.projects.length > 0;
            state.projects = pushData
                ? [...state.projects, ...list]
                : list;
            state.totalProjects = count;
        },
        fetchProjectsFail: (state, action) => {
            state.projectsLoading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchProjectDetailStart,
    fetchProjectDetailSuccess,
    fetchProjectDetailFail,
    fetchProjectsStart,
    fetchProjectsSuccess,
    fetchProjectsFail
} = projectSlice.actions;

export default projectSlice.reducer;