import { takeEvery, put, call } from 'redux-saga/effects';
import { getRequest } from '../../network';
import { GET_PROJECTS, GET_PROJECT } from '../apis';
import { fetchProjectDetailFail, fetchProjectDetailStart, fetchProjectDetailSuccess, fetchProjectsFail, fetchProjectsStart, fetchProjectsSuccess } from 'store/slices/projectSlice';

function* fetchProjectsSaga(action) {
    try {
        const response = yield call(getRequest, GET_PROJECTS, action.payload);
        yield put(fetchProjectsSuccess(response));
    } catch (error) {
        yield put(fetchProjectsFail(error.message));
    }
}

function* fetchProjectSaga(action) {
    try {
        const response = yield call(getRequest, GET_PROJECT, action.payload);
        yield put(fetchProjectDetailSuccess(response));
    } catch (error) {
        yield put(fetchProjectDetailFail(error.message));
    }
}


// Watcher saga
export function* watchProjectSagas() {
    yield takeEvery(fetchProjectsStart.type, fetchProjectsSaga);
    yield takeEvery(fetchProjectDetailStart.type, fetchProjectSaga);
}