import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reviewsLoading: false,
    reviews: [],
    totalReviews: 0,
    reviewLoading: false,
    review: {},
    error: null,
};

const reviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {
        fetchReviewDetailStart: (state) => {
            state.reviewLoading = true;
        },
        fetchReviewDetailSuccess: (state, action) => {
            state.reviewLoading = false;
            state.review = action.payload.data;
        },
        fetchReviewDetailFail: (state, action) => {
            state.reviewLoading = false;
        },
        fetchReviewsStart: (state) => {
            state.reviewsLoading = true;
        },
        fetchReviewsSuccess: (state, action) => {
            state.reviewsLoading = false;
            const { list, page, count } = action.payload.data;
            const pushData = page > 1 &&
                state.reviews.length > 0;
            state.reviews = pushData
                ? [...state.reviews, ...list]
                : list;
            state.totalReviews = count;
        },
        fetchReviewsFail: (state, action) => {
            state.reviewsLoading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchReviewDetailStart,
    fetchReviewDetailSuccess,
    fetchReviewDetailFail,
    fetchReviewsStart,
    fetchReviewsSuccess,
    fetchReviewsFail
} = reviewSlice.actions;

export default reviewSlice.reducer;