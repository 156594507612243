export const LOGIN_OTP = 'auth/login';
export const LOGIN_PASSWORD = 'auth/loginWithPassword';
export const UNREGISTER = 'auth/unRegisterDevice';

export const GET_USER_DETAILS = 'user/getUserDetails';
export const GET_USERS = 'user/getUsers';
export const GET_NOT_FOLLOWED_USERS = 'user/getNotFollowedUsers';
export const TEST_FOLLOW = 'user/testFollow';
export const FOLLOW = 'user/follow';


export const CREATE_POST = 'post/createPost';
export const UPDATE_POST = 'post/updatePost';
export const CHANGE_POST_STATUS = 'post/changePostStatus';
export const GET_POSTS = 'post/getPosts';
export const GET_POST_DETAILS = 'post/getPostDetails';
export const GET_USER_POSTS = 'post/getUserPosts';
export const GET_POST_COMMENTS = 'post/getPostComments';
export const ADD_POST_COMMENT = 'post/addComment';
export const ADD_POST_REPLY = 'post/addReply';
export const REMOVE_POST_COMMENT = 'post/removeComment';
export const REMOVE_POST_REPLY = 'post/removeReply';
export const ADD_POST_REACT = 'post/react';
export const GET_NOT_LIKED_POSTS = 'post/getNotLikedPosts';
export const TEST_REACT = 'post/testReact';
export const GET_SAVED_POSTS = 'post/getSavedPosts';

export const GET_CHATS = 'chat/getChats';
export const GET_MESSAGES = 'chat/getMessages';

export const CREATE_DISCUSSION = 'discussion/addDiscussion';
export const UPDATE_DISCUSSION = 'discussion/updateDiscussion';
export const CHANGE_DISCUSSION_STATUS = 'discussion/changeDiscussionStatus';
export const GET_DISCUSSIONS = 'discussion/getDiscussionPosts';
export const GET_USER_DISCUSSIONS = 'discussion/getUserDiscussions';
export const GET_DISCUSSION_COMMENTS = 'discussion/getComments';
export const ADD_DISCUSSION_COMMENT = 'discussion/addComment';
export const ADD_DISCUSSION_REACT = 'discussion/reactOnDiscussion';
export const ADD_DISCUSSION_REPLY = 'discussion/addReply';
export const REMOVE_DISCUSSION_COMMENT = 'discussion/removeComment';
export const REMOVE_DISCUSSION_REPLY = 'discussion/removeReply';

export const GET_NOTIFICATION = 'notification/getNotifications';
export const DELETE_NOTIFICATION = 'notification/deleteNotification';
export const READ_NOTIFICATION = 'notification/markAsRead';

export const GET_POST_TAGS = 'util/getPostTags';
export const GET_SERVICE_LOCATIONS = 'util/getServiceLocations';
export const GET_PROFESSIONS = 'util/getProfessions';
export const ADD_INQUIRY = 'util/addInquiry';

export const GET_PROJECTS = 'project/getProjects';
export const GET_PROJECT = 'project/getProject';

export const GET_REVIEWS = 'review/getReviews';
export const GET_REVIEW = 'review/getReview';

export const GET_USER_TRANSACTION_SERVICES = 'transactionService/getUserTransactionServices';
export const GET_USER_TRANSACTION_SERVICE = 'transactionService/getUserTransactionService';

export const GET_TV_POSTS = 'tvPost/getTvPosts';
export const GET_TV_POST_DETAIL = 'tvPost/getTvPostDetail';