export const styles = {
  logo: mobile => ({
    height: mobile ? '16px' : '30px',
    width: mobile ? '60px' : '100px',
    cursor: 'pointer'
  }),
  buttons: (selected, mobile) => ({
    color: selected ? 'primary' : '#000',
    fontSize: mobile ? '12px' : '16px'
  }),
  playIcon: { width: '140px', height: '50px' }
};
