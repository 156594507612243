import { lazy } from 'react';

import Loadable from '../components/Loadable';
import MinimalLayout from '../components/MinimalLayout';
import { ROUTES } from '../utils/constants';

const Home = Loadable(lazy(() => import('pages/home')));
const Professionals = Loadable(lazy(() => import('pages/professionals')));
const Profile = Loadable(lazy(() => import('pages/profile')));
const PostDetail = Loadable(lazy(() => import('pages/postDetail')));
const ProjectDetail = Loadable(lazy(() => import('pages/projectDetail')));
const Loans = Loadable(lazy(() => import('pages/loans')));
const Design = Loadable(lazy(() => import('pages/designs')));
const MaterialSuppliers = Loadable(lazy(() => import('pages/materialSuppliers')));
const Privacy = Loadable(lazy(() => import('pages/privacy')));
const Tos = Loadable(lazy(() => import('pages/tos')));
const ContactUs = Loadable(lazy(() => import('pages/contactus')));


const MainRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: ROUTES.ROOT,
            element: <Home />
        },
        {
            path: ROUTES.PROFESSIONALS,
            element: <Professionals />
        },
        {
            path: ROUTES.USERNAME,
            element: <Profile />
        },
        {
            path: ROUTES.POST_DETAIL,
            element: <PostDetail />
        },
        {
            path: ROUTES.PROJECT_DETAIL,
            element: <ProjectDetail />
        },
        {
            path: ROUTES.LOANS,
            element: <Loans />
        },
        {
            path: ROUTES.DESIGNS,
            element: <Design />
        },
        {
            path: ROUTES.MATERIAL_SUPPLIER,
            element: <MaterialSuppliers />
        },
        {
            path: ROUTES.PRIVACY_POLICY,
            element: <Privacy />
        },
        {
            path: ROUTES.TOS,
            element: <Tos />
        },
        {
            path: ROUTES.CONTACT_US,
            element: <ContactUs />
        },
    ]
};

export default MainRoutes;
