import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    transactionServicesLoading: false,
    transactionServices: [],
    totalTransactionServices: 0,
    transactionServiceLoading: false,
    transactionService: {},
    error: null,
};

const transactionServiceSlice = createSlice({
    name: 'transactionService',
    initialState,
    reducers: {
        fetchTransactionServiceDetailStart: (state) => {
            state.transactionServiceLoading = true;
        },
        fetchTransactionServiceDetailSuccess: (state, action) => {
            state.transactionServiceLoading = false;
            state.transactionService = action.payload.data;
        },
        fetchTransactionServiceDetailFail: (state, action) => {
            state.transactionServiceLoading = false;
        },
        fetchTransactionServicesStart: (state) => {
            state.transactionServicesLoading = true;
        },
        fetchTransactionServicesSuccess: (state, action) => {
            state.transactionServicesLoading = false;
            const { list, page, count } = action.payload.data;
            const pushData = page > 1 &&
                state.transactionServices.length > 0;
            state.transactionServices = pushData
                ? [...state.transactionServices, ...list]
                : list;
            state.totalTransactionServices = count;
        },
        fetchTransactionServicesFail: (state, action) => {
            state.transactionServicesLoading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchTransactionServiceDetailStart,
    fetchTransactionServiceDetailSuccess,
    fetchTransactionServiceDetailFail,
    fetchTransactionServicesStart,
    fetchTransactionServicesSuccess,
    fetchTransactionServicesFail
} = transactionServiceSlice.actions;

export default transactionServiceSlice.reducer;