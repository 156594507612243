import { takeEvery, put, call } from 'redux-saga/effects';
import {
    fetchPostsStart,
    fetchPostsSuccess,
    fetchPostsFail,

    fetchUserPostsStart,
    fetchUserPostsSuccess,
    fetchUserPostsFail,

    fetchSavedPostsStart,
    fetchSavedPostsSuccess,
    fetchSavedPostsFail,

    fetchPostCommentsStart,
    fetchPostCommentsSuccess,
    fetchPostCommentsFail,

    fetchPostRepliesStart,
    fetchPostRepliesSuccess,
    fetchPostRepliesFail,

    fetchPostDetailStart,
    fetchPostDetailSuccess,
    fetchPostDetailFail
} from '../slices/postSlice';

import { getRequest } from '../../network';
import {
    GET_POSTS,
    GET_USER_POSTS,
    GET_POST_DETAILS,
    GET_POST_COMMENTS,
    GET_SAVED_POSTS
} from '../apis';

function* fetchPostsSaga(action) {
    try {
        const response = yield call(getRequest, GET_POSTS, action.payload);
        yield put(fetchPostsSuccess(response));
    } catch (error) {
        yield put(fetchPostsFail(error.message));
    }
}

function* fetchPostDetailSaga(action) {
    try {
        const response = yield call(getRequest, GET_POST_DETAILS, action.payload);
        yield put(fetchPostDetailSuccess(response));
    } catch (error) {
        yield put(fetchPostDetailFail(error.message));
    }
}

function* fetchUserPostsSaga(action) {
    try {
        const response = yield call(getRequest, GET_USER_POSTS, action.payload);
        yield put(fetchUserPostsSuccess(response));
    } catch (error) {
        yield put(fetchUserPostsFail(error.message));
    }
}

function* fetchSavedPostsSaga(action) {
    try {
        const response = yield call(getRequest, GET_SAVED_POSTS, action.payload);
        yield put(fetchSavedPostsSuccess(response));
    } catch (error) {
        yield put(fetchSavedPostsFail(error.message));
    }
}

function* fetchPostCommentsSaga(action) {
    try {
        const response = yield call(getRequest, GET_POST_COMMENTS, action.payload);
        yield put(fetchPostCommentsSuccess(response));
    } catch (error) {
        yield put(fetchPostCommentsFail(error.message));
    }
}

function* fetchPostRepliesSaga(action) {
    try {
        const response = yield call(getRequest, GET_POST_COMMENTS, action.payload);
        yield put(fetchPostRepliesSuccess(response));
    } catch (error) {
        yield put(fetchPostRepliesFail(error.message));
    }
}


export function* watchPostSagas() {
    yield takeEvery(fetchPostsStart.type, fetchPostsSaga);
    yield takeEvery(fetchUserPostsStart.type, fetchUserPostsSaga);
    yield takeEvery(fetchSavedPostsStart.type, fetchSavedPostsSaga);
    yield takeEvery(fetchPostCommentsStart.type, fetchPostCommentsSaga);
    yield takeEvery(fetchPostRepliesStart.type, fetchPostRepliesSaga);
    yield takeEvery(fetchPostDetailStart.type, fetchPostDetailSaga);
}